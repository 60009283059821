<template>
  <div>
    <div id="ListOperationsInMass">
      <div class="giga-content_container">
        <div class="giga-actions">
          <div class="giga-actions_left">
            <ul class="giga-actions_row">
              <li class="giga-action_item">
                <div class="giga-action_search">
                  <input
                    type="search"
                    name=""
                    id=""
                    placeholder="Recherche"
                    v-model="search"
                    @input="operationSearch()"
                  />
                  <button class="giga-search_button">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7448 22.6899L17.772 16.7171C21.2724 12.4326 20.6421 6.12211 16.3576 2.62172C12.0768 -0.878671 5.76261 -0.248376 2.26222 4.03613C-1.23817 8.32064 -0.607873 14.6311 3.67663 18.1315C7.36461 21.1479 12.6696 21.1479 16.3576 18.1315L22.3304 24.1043C22.7431 24.472 23.3771 24.4382 23.7448 24.0255C24.0862 23.6466 24.0862 23.0726 23.7448 22.6899ZM10.0321 18.3941C5.60879 18.3941 2.02586 14.8149 2.02211 10.3916C2.02211 5.96829 5.60129 2.38536 10.0246 2.38161C14.4479 2.38161 18.0309 5.96078 18.0346 10.3841C18.0346 10.3841 18.0346 10.3879 18.0346 10.3916C18.0271 14.8074 14.4479 18.3866 10.0321 18.3941Z"
                        fill="#8C9DAB"
                      />
                    </svg>
                  </button>
                </div>
                <!-- <div></div> -->
              </li>
              <filter-modal
                :query-config="queryConfig"
                v-on:onFilter="applyFilter($event)"
                v-on:onReloadData="applyFilter($event)"
                :headerTitle="'filtrer_operation'"
                :currentFilter="currentFilter"
              ></filter-modal>
            </ul>
          </div>
        </div>
        <page-loader v-if="loading"></page-loader>
        <table
          v-else
          class="giga-table"
          :aria-describedby="$t('operations_list')"
        >
          <thead>
            <tr>
              <th
                @click="sortTable('institution.siteName')"
                class="sort"
                scope="col"
                :class="sortBy === 'institution.siteName' ? sortDir : ''"
              >
                {{ $t("operation_name") }}
              </th>
              <th
                @click="sortTable('beginDate')"
                class="sort"
                scope="col"
                :class="sortBy === 'beginDate' ? sortDir : ''"
              >
                {{ $t("commitment_date") }}
              </th>
              <th
                @click="sortTable('endDate')"
                class="sort"
                scope="col"
                :class="sortBy === 'endDate' ? sortDir : ''"
              >
                {{ $t("completion_date") }}
              </th>
              <th
                @click="sortTable('operationVolume.precarityVolume')"
                class="sort"
                scope="col"
                :class="
                  sortBy === 'operationVolume.precarityVolume' ? sortDir : ''
                "
              >
                {{ $t("precarious_volume") }}
              </th>
              <th
                @click="sortTable('operationVolume.classicVolume')"
                class="sort"
                scope="col"
                :class="
                  sortBy === 'operationVolume.classicVolume' ? sortDir : ''
                "
              >
                {{ $t("classic_volume") }}
              </th>
              <th
                @click="sortTable('ficheCalcul.fiche.number')"
                class="sort"
                scope="col"
                :class="sortBy === 'ficheCalcul.fiche.number' ? sortDir : ''"
              >
                {{ $t("plug_cee") }}
              </th>
              <th scope="col" class="sort actions-col">
                {{ $t("actions") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="operation in getOperations.content">
              <tr :key="operation.id">
                <td>
                  <p>{{ operation.siteName }}</p>
                </td>
                <td>
                  <p>
                    {{
                      operation.beginDate
                        ? moment(operation.beginDate, "x").format("DD/MM/YYYY")
                        : ""
                    }}
                  </p>
                </td>
                <td>
                  <p>
                    {{
                      operation.endDate
                        ? moment(operation.endDate, "x").format("DD/MM/YYYY")
                        : ""
                    }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ operation.precarityVolume | formatNumber
                    }}<span> kWh cumac</span>
                  </p>
                </td>
                <td>
                  <p>
                    {{ operation.classicVolume | formatNumber
                    }}<span> kWh cumac</span>
                  </p>
                </td>
                <td>
                  <p>{{ operation.ficheNumber }}</p>
                </td>
                <td>
                  <img
                    v-if="loadingEditOperation === operation.id"
                    class="spinner-img"
                    src="@/assets/images/spinner.svg"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/images/edit.svg"
                    alt=""
                    class="cursor-pointer"
                    @click="openModalEstimation(operation)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <pagination
          v-if="rows >= perPage"
          :length="length"
          :rows="rows"
          :perPage="perPage"
          :currentPage="currentPage"
          v-on:changePerPage="changePerPage($event)"
          v-on:changeCurrentPage="changeCurrentPage($event)"
        ></pagination>
      </div>
    </div>
    <edit-estimation-modal
      :id="this.estimationModalId"
      :tabsAddress="this.tabsAddress"
      :tabsFiche="this.tabsFiche"
      :beneficiary="beneficiary"
      :title="this.estimationModalTitle"
      :isReadOnly="false"
      fontSize="h3"
      backdrop-close="false"
      @confirmClick="onEditEstimationModalConfirm()"
      @closeClick="closeModalEditEstimation()"
    ></edit-estimation-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Pagination from "@/components/static/Pagination.vue";
import FilterModal from "@/components/static/FilterModal.vue";
import { queryConstant } from "@/shared/queryConstant";
import pageLoader from "@/components/static/pageLoader.vue";
import PageLoader from "@/components/static/pageLoader.vue";
import EditEstimationModal from "@/views/Simulations/import-mass-estimations/edit-estimation/EditEstimationModal.vue";
import {
  estimationService,
  simulationService
} from "@/views/Simulations/services";
import { estimationMapper } from "@/views/Simulations/services/estimation.mapper";
import { helpers } from "@/shared/helper";
import Vue from "vue";

export default {
  components: {
    EditEstimationModal,
    Pagination,
    FilterModal,
    pageLoader,
    PageLoader
  },
  name: "ListOperationsInMass",
  data() {
    const _pageSize_10 = 10;
    const _pageSize_25 = 25;
    const _pageSize_50 = 50;
    return {
      scrollBarSettings: {
        scrollXMarginOffset: 50
      },
      perPage: 10,
      currentPage: 1,
      length: [_pageSize_10, _pageSize_25, _pageSize_50],
      sortBy: null,
      sortDir: "asc",
      customFilter: queryConstant.setDefaultCustomFilter(),
      currentFilter: {},
      queryConfig: queryConstant.SIMULATION_OPERATION_CONFIG,
      search: "",
      estimationModalId: "",
      tabsAddress: [],
      tabsFiche: [],
      estimationModalTitle: "",
      beneficiary: {
        isMoral: false,
        physical: {
          id: "",
          email: "",
          lastName: "",
          name: "",
          natureOccupation: "",
          tele1: "",
          tele2: ""
        },
        moral: {
          id: "",
          addressName: "",
          codeCommuneInsee: "",
          codePostal: "",
          libelleAcheminement: "",
          nomCommune: "",
          personneMoralEmail: "",
          personneMoralTelephone: "",
          raisonSociale: "",
          siren: "",
          siret: "",
          type: ""
        }
      },
      payload: null,
      loadingEditOperation: null
    };
  },
  mounted() {
    if (this.isEdit) {
      this.getWorkOperations();
    }
  },
  computed: {
    ...mapGetters("simulation", ["getOperations", "isEdit", "getSimulation"]),
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("loader", { downloadLoader: "getDownloadOperations" }),
    ...mapGetters("period", { periods: "getPeriods" }),
    rows() {
      return this.getOperations.totalElements;
    },
    isVIEWER() {
      return helpers.isVIEWER();
    },
    isAnArtisan() {
      return helpers.isAnArtisan();
    }
  },
  methods: {
    ...mapActions("simulation", ["getSimulationOperationsAction"]),
    ...mapActions("notification", ["openModal", "closeModal"]),
    ...mapMutations("estimation", { goTo: "goNext" }),
    getWorkOperations() {
      this.getSimulationOperationsAction({
        id: parseInt(this.$route.params["id"], 10),
        currentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDir: this.sortDir,
        customFilter: this.customFilter,
        search: this.search
      });
    },
    changePerPage(perPage) {
      this.currentPage = 1;
      this.perPage = perPage;
      this.getWorkOperations();
    },
    changeCurrentPage(currentPage) {
      this.currentPage = currentPage;
      this.getWorkOperations();
    },
    sortTable(name) {
      if (name === this.sortBy) {
        if (this.sortDir === "asc") {
          this.sortDir = "desc";
        } else {
          this.sortDir = "asc";
        }
      }
      this.sortBy = name;
      this.getWorkOperations();
    },
    onRowSelected(idOperation) {
      this.$router.push({
        name: "SimulationsOperation",
        params: {
          id: parseInt(this.$route.params["id"], 10),
          idOperation: idOperation
        }
      });
    },
    applyFilter(filter) {
      this.customFilter = filter.custom;
      this.currentFilter = filter.filters;
      this.currentPage = 1;
      this.getWorkOperations();
    },
    operationSearch() {
      this.getWorkOperations();
    },
    openModalEstimation(operation) {
      this.loadingEditOperation = operation?.id;
      simulationService
        .fetchEstimationsByGlobalOperationId(operation?.id)
        .then(estimations => {
          if (this.isAnArtisan && estimations?.length > 0) {
            this.beneficiary.isMoral = estimations[0].beneficiary.isMoral;
            if (estimations[0].beneficiary?.isMoral) {
              this.beneficiary.moral = estimations[0].beneficiary;
              this.beneficiary.moral.codePostal =
                estimations[0].beneficiary.basePostalCodes.codePostal;
              this.beneficiary.moral.nomCommune =
                estimations[0].beneficiary.basePostalCodes.nomCommune;
            } else {
              this.beneficiary.physical = estimations[0].beneficiary;
            }
          }

          estimations.forEach(estimation => {
            const address = estimationMapper.mapAddresses(
              estimation,
              this.tabAddressIndex
            );
            this.tabsAddress = [address];
            estimation.operationDetails.forEach(operationDetail => {
              const fiche = estimationMapper.mapFiches(
                operationDetail,
                address,
                this.periods,
                this.tabAddressIndex,
                this.tabFicheIndex++
              );
              this.tabsFiche = [fiche];
            });
          });

          this.estimationModalId = "addEstimation";
          this.estimationModalTitle = operation?.ficheNumber;
          setTimeout(() => {
            this.openModal({
              instance: this.$root,
              id: this.estimationModalId
            });
          }, 1);
          this.loadingEditOperation = null;
        });
    },
    onEditEstimationModalConfirm() {
      const payload = {
        tabsAddress: this.tabsAddress,
        tabsFiche: this.tabsFiche,
        id: this.getSimulation.id
      };
      if (this.isAnArtisan) {
        if (this.beneficiary.isMoral) {
          const formBeneficiary = this.beneficiary.moral;
          const moralBeneficiary = {
            addressName: formBeneficiary.addressName,
            basePostalCodes: {
              codeCommuneInsee: formBeneficiary.codeCommuneInsee,
              codePostal: formBeneficiary.codePostal,
              libelleAcheminement: formBeneficiary.libelleAcheminement,
              nomCommune: formBeneficiary.nomCommune
            },
            isMoral: this.beneficiary.isMoral,
            personneMoralEmail: formBeneficiary.personneMoralEmail,
            personneMoralTelephone: formBeneficiary.personneMoralTelephone,
            raisonSociale: formBeneficiary.raisonSociale,
            siren: formBeneficiary.siren,
            siret: formBeneficiary.siret,
            type: formBeneficiary.type
          };
          payload.beneficiary = moralBeneficiary;
        } else {
          payload.beneficiary = this.beneficiary.physical;
        }
      }
      this.getOperations.content;
      estimationService
        .persistEstimations(
          payload.tabsAddress,
          payload.tabsFiche,
          payload.beneficiary,
          payload.id,
          true
        )
        .then(() => {
          this.closeModalEditEstimation();
          Vue.prototype.$notification.success(
            "La mise à jour a été effectuée avec succès"
          );
          this.goTo(1);
          return this.getWorkOperations();
        })
        .catch(error => {
          Vue.prototype.$notification.error(
            "Une erreur est survenue lors de la mise à jour"
          );
        });
    },
    closeModalEditEstimation() {
      this.closeModal({ instance: this.$root, id: this.estimationModalId });
    }
  },
  watch: {
    getOperations(newValue) {
      this.$emit("operations-updated", newValue);
    }
  }
};
</script>
<style lang="scss">
.actions-col {
  width: 5% !important;
}
.spinner-img {
  width: 24px;
}
</style>
